.certify-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  margin: 0 auto;
  padding: 0 40px;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: white;

  .modal-body {
    background-color: white;
    border-radius: 20px;
    width: 100%;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 1200px;
    box-shadow: 0 10px 30px 0 #e3eef1;
    

    h2 {
      font-size: 40px;
      margin: 48px 0 16px;
      text-align: center;
      b {
        font-weight: bold;
      }
    }
    p {
      text-align: center;
      font-size: 18px;
      line-height: 1.8rem;
      color: #959595;
    }
    img {
      height: 80px;
    }
    .modal-input-box {
      padding: 15px 30px;
      margin-top: 24px;
      input {
        border-radius: 0;
        outline: none;
        border: none;
        height: 32px;
        padding: 8px;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        line-height: 1.75rem;
        border: solid 2px #ccc;
        border-radius: 4px;
        transition: border-bottom 0.3s;
        &:focus {
          border: solid 2px black;
        }
        &::placeholder {
          color: #ccc;
          transition: 0.3s;
        }
        &:focus::placeholder {
          opacity: 0;
        }
      }
    }
  }

  .modal-button {
    font-size: 18px;
    border: none;
    cursor: pointer;
    margin: 20px;
    border-radius: 30px;
    padding: 16px 30px;
    width: 250px;
    background-color: blanchedalmond;
    color: black;
    font-weight: bold;
    text-decoration: none;
    display: flex;
    justify-content: center;
  }
}